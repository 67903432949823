
import { getPropertyTransactionsQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import Transactions from "../components/PropertyTransactions.vue";

export default {
  title() {
    return `${this.$t("MENU_TRANSACTIONS")}`;
  },
  apollo: {
    transactions: getPropertyTransactionsQuery,
  },
  components: {
    Transactions,
  },
  computed: {
    loading: function () {
      return this.transactions == null || this.$apollo.queries.transactions.loading;
    },
  },
};
